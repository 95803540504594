<template>
  <div class="wrapper">
    <h1>{{ $t('homework') }}</h1>
    <div class="row">
      <b-col class="mt-md-0 mt-3">
        <div class="tabs-navigator lg d-inline-block">
          <a :class="{ active: subjects === 0 }" @click="subjects = 0">Все задания</a>
          <a :class="{ active: subjects === 1 }" @click="subjects = 1">Не пройденные</a>
          <a :class="{ active: subjects === 2 }" @click="subjects = 2">Завершенные</a>
          <a :class="{ active: subjects === 3 }" @click="subjects = 3">Просроченные</a>
        </div>
        <div class="spacecard min-h-card pb-3">
          <template v-if="subjects === 0">
            <div v-if="homeworks.length" class="homeworks-list">
              <HomeworkPreview v-for="homework in homeworks" :key="`all-${homework.id}`" :homework="homework" />
            </div>
            <p v-else>Отсутствуют</p>
          </template>
          <template v-else-if="subjects === 1">
            <div v-if="unfinished.length" class="homeworks-list">
              <HomeworkPreview v-for="homework in unfinished" :key="`infinished-${homework.id}`" :homework="homework" />
            </div>
            <p v-else>Отсутствуют</p>
          </template>
          <template v-else-if="subjects === 2">
            <div v-if="finished.length" class="homeworks-list">
              <HomeworkPreview v-for="homework in finished" :key="`finished-${homework.id}`" :homework="homework" />
            </div>
            <p v-else>Отсутствуют</p>
          </template>
          <template v-else-if="subjects === 3">
            <p>Отсутствуют</p>
          </template>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import HomeworkPreview from './Preview.vue'
import { StudentPageService } from '@/services/api.service'

export default {
  name: "StudentHomeworkHome",
  components: {
    HomeworkPreview
  },
  data() {
    return {
      subjects: 0,
      homeworks: [],
    }
  },
  mounted() {
    StudentPageService.homeworks().then(res => {
      this.homeworks = res.data.data.homeworks ? res.data.data.homeworks : [];
    })
  },
  computed: {
    unfinished() {
      return this.homeworks.filter(homework => {
        return homework.pivot.homework_done === 0
      })
    },
    finished() {
      return this.homeworks.filter(homework => {
        return homework.pivot.homework_done !== 0
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.homeworks-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media(max-width: 992px) {
  .homeworks-list {
    grid-template-columns: 1fr;
  }
}
</style>